.title {
    /* Frequently Asked Questions */
font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 28px;
line-height: 34px;
/* identical to box height */
text-align: center;

/* Text */
color: #2F2F2F;

}

.text {

/* Body text */
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
/* or 150% */

/* Text */
color: #2F2F2F; 

margin-top: -10px;
margin-bottom: -20px;

}

.root {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.accordeonContaner {
    
    @media(min-width:780px) {
        width: 780px; 
    }
}

.mentorButton {
    background-color: #05D6D8;
    padding: 8px 24px;
    border-radius: 100px;
    cursor: pointer;
}