@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
.title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    color: #FFFFFF;

    margin-bottom: 40px;
}

.reviewText {

    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    color: #FFFFFF;

}

.reviewImage {
    height: 48px;
    width: 48px;
    border-radius: 99999px;
}

.reviewName {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}

.reviewContainer {
    width: 100%;
}

.reviewGrid {
    width: 100%;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
    row-gap: 40px;
    column-gap: 30px;
    align-items: flex-start;
}

div:has(> .root) {
    margin: 0 auto 0 auto;
}

.root {
    padding: 80px 24px 60px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
}