.root {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    gap: 40px;
    justify-content: center;
    margin-bottom: 80px;

    @media (min-width: 768px) {
        flex-direction: row;
    }
}

.title {

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;

    /* Text */
    color: #4A4A4A;

}

.pictureDesktop {
    display: none;
    height: 446px;

    @media (min-width: 768px) {
        display: block;
    }

}

.pictureMobile {
    display: block;
    width: 100%;

    @media (min-width: 768px) {
        display: none;
    }

}

.textContainer {
    width: 100%;
}

.content {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    margin-bottom: 16px;

    /* Text */
    color: #4A4A4A;
}