.title {
    /* Section Title */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    /* identical to box height */
    text-align: center;

    /* Text */
    color: #2F2F2F;
}

.subtitle {
    /* Section Subtitle */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    /* or 133% */
    text-align: center;

    /* Text */
    color: #2F2F2F;

    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.titleContainer {
    max-width: 780px;
}

.rootContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:16px
}

.bodyContainer {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 40px;

    @media (min-width: 768px) {
        flex-direction: row;
    }
}

.entryContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px
}

.listTitle {
    /* Card title */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    /* identical to box height */

    /* Text */
    color: #2F2F2F;
}

.listText {
    /* Body text */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    /* Text */
    color: #2F2F2F;
}

.listImageContainer {

    display: flex;
    align-items: center;
    justify-content: center;

    min-width: 82px;
    height: 82px;
    border-radius: 100%;

    /* Light blue fill */
    background: #F4FCFF;

}

.listImage {
    height: 60px;
}

.imageBig {
    width: 100%;
    @media (min-width: 768px) {
        width: 472px;
    }
}

.mentorButton {
    background-color: #05D6D8;
    padding: 8px 24px;
    border-radius: 100px;
    cursor: pointer;
}